@font-face {
  font-family: 'GillSans';
  src: url('./fonts/GillSans.ttc') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('./fonts/Cocogoose-Pro-Bold-Italic-trial.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('./fonts/Cocogoose-Pro-Bold-trial.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('./fonts/Cocogoose-Pro-Darkmode-Italic-trial.ttf') format('truetype');
  font-weight: bolder;
  font-style: italic;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('./fonts/Cocogoose-Pro-Darkmode-trial.ttf') format('truetype');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('./fonts/Cocogoose-Pro-Italic-trial.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('./fonts/Cocogoose-Pro-Letterpress-Regular-trial.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('./fonts/Cocogoose-Pro-Light-Italic-trial.ttf') format('truetype');
  font-weight: lighter;
  font-style: italic;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('./fonts/Cocogoose-Pro-Light-trial.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('./fonts/Cocogoose-Pro-Regular-trial.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('./fonts/Cocogoose-Pro-Semilight-Italic-trial.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('./fonts/Cocogoose-Pro-Semilight-trial.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('./fonts/Cocogoose-Pro-Thin-Italic-trial.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('./fonts/Cocogoose-Pro-Thin-trial.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('./fonts/Cocogoose-Pro-Ultralight-Italic-trial.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('./fonts/Cocogoose-Pro-Ultralight-trial.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}